<template>
  <v-row no-gutters v-if="userProfile && userProfile.isInitialized && !isComplete" class="pb-3">
    <v-col class="text-center" cols="12">
      <div class="row justify-center" id="incomplete-info-card">
        <template v-if="!userProfile.incompleteInformationsContainsOnlyCandidature">
          Votre profil est rempli à&nbsp;:
          <div :class="!isSmallDevice ? 'col-1' : 'col-3'" class="py-0 d-flex align-center">
            <v-progress-linear
                height="20"
                width="20%"
                class="white--text"
                :value="completedPourcentage"
                rounded>
              <strong>{{ completedPourcentage }}%</strong>
            </v-progress-linear>
          </div>
          <div>Pour améliorer cela, vous pourriez préciser&nbsp;:</div>
        </template>
        <template v-else>
          <strong>Votre profil est complet&nbsp;!</strong>
          &nbsp;Découvrez les offres de nos partenaires&nbsp;:
        </template>
        <div
            v-for="incompleteInfo in userProfile.incompleteInformations.slice(0, 3)"
            :key="incompleteInfo">
          <v-chip
              class="px-3 mx-1 py-1 interaction white--text "
              small
              @click="helpToCompleteInformation(incompleteInfo)"
          >
            <v-icon small class="pr-3 white--text ">fal fa-{{
                HELP_TO_COMPLETE_INFO[incompleteInfo].icon
              }}
            </v-icon>
            {{ HELP_TO_COMPLETE_INFO[incompleteInfo].description }}
          </v-chip>
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import userProfile from '@/views/home/UserProfileProvider';
import HELP_TO_COMPLETE_INFO from '@/domain/user/HelpToCompleteInfo';

export default {
  name: 'incomplete-informations',
  data() {
    return {
      userProfile,
      HELP_TO_COMPLETE_INFO,
    };
  },
  computed: {
    completedPourcentage() {
      return this.userProfile?.completion;
    },
    isComplete() {
      return !this.userProfile?.incompleteInformations?.length;
    },
    isSmallDevice() {
      return (
        this.$vuetify.breakpoint.name === 'sm'
          || this.$vuetify.breakpoint.name === 'xs'
      );
    },
  },
  async created() {
    await this.userProfile.initialize();
  },
  methods: {
    async helpToCompleteInformation(code) {
      let route = this.HELP_TO_COMPLETE_INFO[code]?.link;
      if (this.$route.name !== route) {
        await this.$router.push({name: route});
      }
    },
  },

};
</script>
<style>

.code--two-lines > span {
  display: inline-block;
}

</style>
