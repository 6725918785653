import Vue from 'vue';
import { IncompleteInformation, Location, Situation, UserProfileSummary } from 'erhgo-api-client';
import SafeService from 'odas-plugins/SafeService';
import keycloakService from 'odas-plugins/KeycloakService';
import asyncDebounce from 'async-debounce';

class UserProfileProvider extends SafeService {

  private static instance: UserProfileProvider;
  private _profile: UserProfileSummary | null = null;
  private _receiveJobOfferEmails = false;
  private _receiveJobDatingEmails = false;
  private _completion = 0;
  private _successUpdate = false;
  private _initialized: Date | null = null;
  private _pendingInitialization = false;
  private _initializing: Promise<void> | null = null;
  private static _PROFILE_DATA_EXPIRE_AFTER_MS = 5000;


  static getInstance(): UserProfileProvider {
    if (!UserProfileProvider.instance) {
      UserProfileProvider.instance = new UserProfileProvider();
    }
    return UserProfileProvider.instance;
  }

  constructor() {
    super();
  }

  async initialize() {
    // Races condition: await for potential previous initializing promise
    if (this._pendingInitialization) {
      await this._initializing;
    } else if (this.isProfileRefreshRequired) {
      this._initializing = this.safeCall(async () => {
        this._pendingInitialization = true;
        try {
          const _profile = (await Vue.$api.getUserProfile(keycloakService.userId)).data;
          this._receiveJobOfferEmails = !!(await Vue.$api.getUserJobOffersOptIn(keycloakService.userId)).data.value;
          this._receiveJobDatingEmails = !!(await Vue.$api.getUserJobDatingNotifyOptIn(keycloakService.userId)).data.value;
          this._completion = (await Vue.$api.getUserProfileCompletion()).data.value ?? 0;
          this._initialized = new Date();
          this._profile = _profile;
        } finally {
          this._pendingInitialization = false;
        }
      });
      await this._initializing;
    }
  }

  private get isProfileRefreshRequired() {
    return (!this._initialized || (this._initialized < new Date(Date.now() - UserProfileProvider._PROFILE_DATA_EXPIRE_AFTER_MS)));
  }

  private _debouncedUpdate = asyncDebounce(async () => {
    await this.safeCall(async () => {
      this._successUpdate = false;
      await Vue.$api.setUserContactInfo(keycloakService.userId, {...this._profile});
      this._successUpdate = true;
      setTimeout(() => this._successUpdate = false, 4000);
    }, true);
  }, 500);

  update() {
    this._debouncedUpdate.fn();
  }


  get updatableFieldsView() {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      initialized,
      location,
      salary,
    } = this;
    return {
      firstName,
      lastName,
      email,
      phoneNumber,
      initialized,
      location,
      salary,
    };
  }

  async deleteUser() {
    await this.safeCall(async () => {
      await Vue.$api.deleteUser(keycloakService.userId);
    });
  }

  async setFrontOfficeUserPassword(password: string) {
    await this.safeCall(async () => {
      await Vue.$api.setFrontOfficeUserPassword({userId: keycloakService.userId, password});
    });
  }

  async setUserEmail(email: string) {
    try {
      await Vue.$api.setUserContactInfo(keycloakService.userId, {email});
      this._profile!.email = email;
    } catch (err) {
      throw err;
    }
  }

  async updateUserLocation(location: Location) {
    await this.safeCall(async () => {
      await Vue.$api.setUserCity({location});
      this._profile!.location = location;
    });
  }

  get isAvailable() {
    return this._profile?.situation === Situation.RESEARCHING;
  }

  get situation() {
    return (this._profile && this._profile.situation) ? this._profile.situation : null;
  }

  set situation(situation: Situation | null) {
    if (this._profile?.situation !== situation) {
      this._profile!.situation = situation ?? undefined;
      if (this.situation) {
        this.setUserSituation(this.situation, this.delayInMonth);
      }
      this.refreshIncompleteInformations();
    }
  }

  async setUserSituation(situation: Situation, delayInMonth: number | null) {
    await this.safeCall(async () => {
      await Vue.$api.setUserSituation({ situation: situation, delayInMonth: delayInMonth ?? undefined });
      this._successUpdate = true;
      setTimeout(() => this._successUpdate = false, 4000);
    });
  }


  get firstName() {
    return (this._profile && this._profile.firstName) ? this._profile.firstName : '';
  }

  set firstName(firstName: string) {
    this._profile!.firstName = firstName;
    this.refreshIncompleteInformations();
  }

  get delayInMonth() {
    return this.situation === Situation.RESEARCHING ? (this._profile!.delayInMonth ?? null) : null;
  }

  set delayInMonth(delay: number | null) {
    if (this._profile?.delayInMonth !== delay) {
      this._profile!.delayInMonth = delay ?? undefined;
      if (this.situation) {
        this.setUserSituation(this.situation, this.delayInMonth);
      }
    }
  }

  get incompleteInformations() {
    return this._profile?.incompleteInformations;
  }

  get completion() {
    return this._completion;
  }

  get requiresIncompleteProfileDialog() {
    return !!this.incompleteInformations?.length && !this.incompleteInformationsContainsOnlyCandidature;
  }

  get lastName() {
    return (this._profile && this._profile.lastName) ? this._profile.lastName : '';
  }

  set lastName(lastName: string) {
    this._profile!.lastName = lastName;
    this.refreshIncompleteInformations();
  }

  get fullname() {
    return `${this.firstName} ${this.lastName}`;
  }

  get isInitialized() {
    return !!this._profile;
  }

  get phoneNumber() {
    return (this._profile && this._profile.phoneNumber) ? this._profile.phoneNumber : '';
  }

  set phoneNumber(phoneNumber: string) {
    this._profile!.phoneNumber = phoneNumber;
    this.refreshIncompleteInformations();
  }

  get contactTime() {
    return this._profile?.contactTime ?? null;
  }

  set contactTime(contactTime) {
    this._profile!.contactTime = contactTime ?? undefined;
  }

  get email() {
    return this._profile?.email ?? '';
  }

  set email(email: string) {
    this._profile!.email = email;
  }

  get location() {
    return this._profile?.location ?? null;
  }

  set location(location: Location | null) {
    this._profile!.location = location ?? undefined;
    this.refreshIncompleteInformations();
  }

  get birthDate() {
    return this._profile?.birthDate ?? null;
  }

  set birthDate(birthDate) {
    this._profile!.birthDate = birthDate ?? undefined;
  }

  get salary() {
    return this._profile?.salary;
  }

  set salary(value) {
    if (value !== this.salary) {
      this._profile!.salary = value;
      this.refreshIncompleteInformations();
    }
  }

  get receiveJobOfferEmails() {
    return this._receiveJobOfferEmails;
  }

  set receiveJobOfferEmails(value) {
    if (value !== this._receiveJobOfferEmails) {
      this._receiveJobOfferEmails = value;
      this._debouncedUpdateJobOffersOptIn.fn();
    }
  }

  private _debouncedUpdateJobOffersOptIn = asyncDebounce(async () => {
    await this.safeCall(async () => {
      await Vue.$api.updateUserJobOffersOptIn({
        userId: keycloakService.userId,
        value: this._receiveJobOfferEmails,
      });
      this._successUpdate = true;
      setTimeout(() => this._successUpdate = false, 4000);
    }, true);
  }, 500);

  private _debouncedUpdateReceiveJobDatingEmails = asyncDebounce(async () => {
    await this.safeCall(async () => {
      await Vue.$api.updateUserJobDatingNotifyOptIn({
        userId: keycloakService.userId,
        value: this._receiveJobDatingEmails,
      });
      this._successUpdate = true;
      setTimeout(() => this._successUpdate = false, 4000);
    }, true);
  }, 500);


  get receiveJobDatingEmails() {
    return this._receiveJobDatingEmails;
  }

  set receiveJobDatingEmails(value) {
    if (value !== this._receiveJobDatingEmails) {
      this._receiveJobDatingEmails = value;
      this._debouncedUpdateReceiveJobDatingEmails.fn();
    }
  }

  get candidatures() {
    return this._profile ? this._profile.lastCandidatures : [];
  }

  get experiences() {
    return this._profile ? this._profile.lastExperiences : [];
  }

  get contactInfo() {
    const { phoneNumber, contactTime, location } = this._profile ?? {};
    return {phoneNumber, contactTime, location};
  }

  get isExportable() {
    return this._profile?.isExportable;
  }

  get missingContact() {
    return this.isInitialized && (!this.location?.postcode || !this.phoneNumber || !this.firstName || !this.lastName);
  }

  get isSourceInitialized() {
    return this._profile?.isSourceInitialized;
  }

  get criteria() {
    return this._profile?.criteria;
  }

  get successUpdate() {
    return this._successUpdate;
  }

  get initialized() {
    return !!this._initialized;
  }

  get includesMenus() {
    return this._profile?.includesMenus ?? [];
  }

  get unreadNotificationsCount() {
    return this._profile?.unreadNotificationsCount;
  }

  get behaviors() {
    return this._profile?.behaviors ?? [];
  }

  decreaseUnreadNotificationsCount() {
    if (this._profile?.unreadNotificationsCount) {
      this._profile.unreadNotificationsCount--;
    }
  }

  resetUnreadNotificationsCount() {
    if (this._profile?.unreadNotificationsCount) {
      this._profile.unreadNotificationsCount = 0;
    }
  }

  get incompleteInformationsContainsOnlyCandidature() {
    return this._profile?.incompleteInformations?.length === 1 && this._profile?.incompleteInformations?.includes(IncompleteInformation.INACTIVE_CANDIDATURE);
  }

  get blacklistedOccupations() {
    return this._profile?.blacklistedOccupations;
  }

  get softSkillsStatus() {
    return this._profile?.softSkillsStatus;
  }

  async blackListOccupation(occupationId: string) {
    await this.safeCall(async () => {
      await Vue.$api.setUserBlacklistOccupations({
        userId: keycloakService.userId,
        occupationId,
      });
    });
  }

  async removeBlackListOccupation(occupationId: string) {
    await this.safeCall(async () => {
      await Vue.$api.removeUserBlacklistOccupations({
        userId: keycloakService.userId,
        occupationId,
      });
      this._profile!.blacklistedOccupations = this._profile?.blacklistedOccupations?.filter(o => o.id !== occupationId);
    });
  }

  async refreshBlacklistedOccupations() {
    await this.safeCall(async () => {
      const _profile = (await Vue.$api.getUserProfile(keycloakService.userId)).data;
      this._profile!.blacklistedOccupations = _profile.blacklistedOccupations;
    });
  }

  private refreshIncompleteInformations() {
    [
      {data: this.firstName && this.lastName, field: IncompleteInformation.NAME},
      {data: this.location, field: IncompleteInformation.LOCATION},
      {data: this.salary, field: IncompleteInformation.SALARY},
      {data: this.phoneNumber, field: IncompleteInformation.PHONE},
      {data: this.situation, field: IncompleteInformation.SITUATION},
    ].forEach(({data, field}) => this.addOrRemoveIncompleteInformation(!!data, field));
  }

  private addOrRemoveIncompleteInformation(value: boolean, name: IncompleteInformation) {
    if (value) {
      this._profile!.incompleteInformations = this._profile!.incompleteInformations?.filter(i => i !== name) ?? [];
    } else {
      this._profile!.incompleteInformations = [...new Set([...(this._profile!.incompleteInformations ?? []), name])];
    }
  }

}

export default UserProfileProvider.getInstance();
